import { gsap } from 'gsap';
import Plyr from 'plyr';
import magnetize from '@/components/MagneticLinks';

export default {
    init() {
        const videoPlayer = document.querySelectorAll('[data-video-player]');

        videoPlayer.forEach(video => {
            const cover = video.querySelector('[data-video-cover]');
            const button = video.querySelector('[data-video-button]');
            const player = new Plyr(video.querySelector('[data-plyr]'), {
                controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
            });

            cover.addEventListener('click', function (ev) {
                player.play();

                gsap.to(cover, {
                    duration: 0.3,
                    autoAlpha: 0
                });
            });

            button.addEventListener('mousemove', function (ev) {
                magnetize.start(ev, button, button, 80);
            });

            button.addEventListener('mouseleave', function (ev) {
                magnetize.stop(button);
            });
        });
    }
};
