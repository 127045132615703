import vidPlayer from '@/modules/VideoPlayer.js';
import scrollSections from '@/modules/ScrollSections.js';

console.log(
    `%cCrafted with ❤ by https://whiteboard.is`,
    'background: #5e9499; color: #ffffff; font-size: 13px; padding: 4px 8px;'
);

/* :: Load!
{+} ---------------------------------- */
window.onload = () => {
    vidPlayer.init();
    scrollSections.init();
};
