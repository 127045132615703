import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
    init() {
        const scrollTrack = document.querySelector('[data-scroll-track]');

        if (scrollTrack) {
            ScrollTrigger.matchMedia({
                '(min-width: 992px)': function () {
                    let sections = gsap.utils.toArray('[data-scroll-section]'),
                        currentSection = sections[0];

                    /* :: Set Track Height
                    {+} ---------------------------------- */
                    const steps = sections.length * 150;
                    gsap.set(scrollTrack, { height: steps + 'vh' });

                    // π ----
                    // :: PIN THE CONTAINER IN THE TRACK ---------------------------::
                    // ____
                    ScrollTrigger.create({
                        trigger: scrollTrack,
                        start: 'top top',
                        end: 'bottom bottom',
                        pin: '[data-scroll-container]'
                    });

                    console.log(`Entry Point: ${scrollTrack.offsetTop}`);

                    // π ----
                    // :: SECTIONS ---------------------------::
                    // ____
                    sections.forEach((section, i) => {
                        const last = section.hasAttribute('data-scroll-last');

                        let enterStart = scrollTrack.offsetTop + (i - 1 + i / 2) * innerHeight;
                        let enterEnd = scrollTrack.offsetTop + (i + i / 2) * innerHeight;

                        let leaveStart = scrollTrack.offsetTop + (i + 0.5 + i / 2) * innerHeight;
                        let leaveEnd = scrollTrack.offsetTop + (i + 1.5 + i / 2) * innerHeight;

                        if (i === 0) {
                            enterStart = scrollTrack.offsetTop - innerHeight * 0.8;
                            enterEnd = scrollTrack.offsetTop;

                            leaveStart = scrollTrack.offsetTop + 0.5 * innerHeight;
                            leaveEnd = scrollTrack.offsetTop + 1.5 * innerHeight;
                        }

                        const side01 = section.querySelector('[data-side-01]');
                        const side02 = section.querySelector('[data-side-02]');

                        gsap.set(side01, { yPercent: 104 });
                        gsap.set(side02, { yPercent: -104 });

                        /* :: Enter In
                        {+} ---------------------------------- */
                        const enter = gsap.timeline({
                            scrollTrigger: {
                                trigger: scrollTrack,
                                scrub: 0.5,
                                start: () => enterStart,
                                end: () => enterEnd
                            }
                        });

                        enter
                            .add('start')
                            .set(section, { autoAlpha: 1 })
                            .to(side01, { yPercent: 0 }, 'start')
                            .to(side02, { yPercent: 0 }, 'start');

                        if (!last) {
                            /* :: Leave
                            {+} ---------------------------------- */
                            const leave = gsap.timeline({
                                scrollTrigger: {
                                    trigger: scrollTrack,
                                    scrub: 0.5,
                                    start: () => leaveStart,
                                    end: () => leaveEnd
                                }
                            });

                            leave
                                .add('start')
                                .to(side01, { yPercent: -104 }, 'start')
                                .to(side02, { yPercent: 104 }, 'start')
                                .set(section, { autoAlpha: 0 });
                        }
                    });
                }
            });
        }
    }
};
